@import '@core/styles/_colors.scss';

.not-found-container {
	display: flex;
  flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 24px;

	// & > svg {
	// 	width: 100%;
	// }

	& > h1 {
		font-weight: 900;
		font-size: 24px;
		color: $colorGray70;
	}

	& > p {
		font-weight: 400;
		font-size: 14px;
		color: $colorGray70;
    text-align: center;
	}

  & > .action {
    padding: 12px;
  }
}
